import React from "react";
import { Text, Grid, Box } from "@mantine/core";

export const renderReservation = (data) => {
  return (
    <Grid>
      <Grid.Col span={12}>
        <Box my={10} sx={{ borderBottom: "1px solid #004236" }}>
          <Text ta="justify" size="sm" color="#004236" mb={10}>
            Estos servicios fueron reservados correctamente:
          </Text>
        </Box>
      </Grid.Col>
      {data
        ?.sort((a, b) => a.servicio?.localeCompare(b.servicio))
        ?.map((item, index) => (
          <React.Fragment key={index}>
            <Grid.Col span={4}>
              <Text size="sm">{`Servicio: ${item.servicio}`}</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <Text size="sm">{`Reserva: ${item.codReserva}`}</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <Text size="sm">
                {item.servicio === "AVION" ? `Vuelo: ${item.vuelo}` : ""}
              </Text>
            </Grid.Col>
          </React.Fragment>
        ))}
    </Grid>
  );
};

export const renderConfirmServices = (data) => {
  return (
    <Grid>
      <Grid.Col span={12}>
        <Box my={10} sx={{ borderBottom: "1px solid #004236" }}>
          <Text ta="justify" size="sm" color="#004236" mb={10}>
            Los siguientes servicios están disponibles para su reserva, desea
            seguir con la aprobación del viaje? (el botón de aprobación, se
            encontrará deshabilitado, cuando uno de los servicios de vuelo no se
            encuentre disponible):
          </Text>
        </Box>
      </Grid.Col>
      {data.map((item, index) => (
        <React.Fragment key={index}>
          <Grid.Col span={4}>
            <Text size="sm">{item.service}</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text size="sm">{`${item.origin} - ${item.destination}`}</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text size="sm">{`Disponibilidad: ${
              item.valor > 0 ? "Si" : "No"
            }`}</Text>
          </Grid.Col>
        </React.Fragment>
      ))}
    </Grid>
  );
};
