import { Grid, Text, Flex, Button } from "@mantine/core";
import { Fragment } from "react";
const Novelty = ({
  item,
  isMulti,
  isInternational,
  isApprover,
  handleApproveNovelty,
  handleRejectNovelty,
  classes,
  utils,
}) => {
  const status = {
    PENDAPROBO: "Pendiente de aprobación",
    APROBADO: "Aprobado",
    RECHAZADO: "Rechazado",
  };

  return (
    <>
      <Grid.Col>
        <Grid>
          <Grid.Col>
            <Text size={20} fw={700} color="#004236">
              {`Estado: `}
              <Text span c="orange" inherit>
                {status[item?.IdEstado]}
              </Text>
            </Text>
          </Grid.Col>
          <Grid.Col>
            <Text size="sm" fw={700} color="#004236">
              Justificación de la novedad:
            </Text>
            <Text size="sm">{item?.Justificacion}</Text>
          </Grid.Col>
          {(isMulti ? item?.Trayectos : [item?.Trayectos[0]])?.map(
            (t, index) => (
              <Fragment key={index}>
                <Grid.Col span={4}>
                  <Text size="sm" fw={700} color="#004236">
                    Fecha final solicitada
                  </Text>
                  <Text size="sm">
                    {utils.parseDateLocaleFormat(t?.FechaFinalInicialViaje)}
                  </Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text size="sm" fw={700} color="#004236">
                    Fecha final novedad
                  </Text>
                  <Text size="sm">
                    {utils.parseDateLocaleFormat(t?.FechaFinalPorNovedad)}
                  </Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text size="sm" fw={700} color="#004236">
                    Días por liquidar
                  </Text>
                  <Text size="sm">{t?.DiasPendientesLiquidar}</Text>
                </Grid.Col>
              </Fragment>
            )
          )}
          <Grid.Col>
            <Text color="#004236" fw={700}>
              Los nuevos días solicitados en la novedad aún no se ha liquidado,
              ni totalizado.
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      {item?.Gastos?.length > 0 && (
        <Grid.Col>
          <Grid>
            <Grid.Col span={4}>
              <Text size="sm" fw={700} color="#004236">
                Concepto
              </Text>
            </Grid.Col>

            <Grid.Col span={4}>
              <Text
                sx={{
                  display: !isInternational && "none",
                }}
                ta="right"
                size="sm"
                fw={700}
                color="#004236"
              >
                Dólares
              </Text>
            </Grid.Col>

            <Grid.Col span={4}>
              <Text size="sm" ta="right" fw={700} color="#004236">
                Pesos
              </Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      )}
      {item?.Gastos?.map((expense, index) => (
        <Grid.Col
          sx={{
            background: index % 2 !== 0 ? "transparent" : "#F5F5F5",
          }}
          p={15}
          key={index}
        >
          <Grid>
            <Grid.Col span={4}>
              <Text size="sm">{expense?.NombreGasto}</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <Text
                sx={{
                  display: !isInternational && "none",
                }}
                size="sm"
                ta="right"
              >
                {expense?.Moneda === "USD"
                  ? utils.formatterUSD.format(expense?.Valor)
                  : "$0  USD"}
              </Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <Text size="sm" ta="right">
                {expense?.Moneda === "USD"
                  ? "$0  COP"
                  : utils.formatter.format(expense?.Valor)}
              </Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      ))}

      {item?.IdEstado === "PENDAPROBO" && isApprover && (
        <Grid.Col>
          <Flex gap={16}>
            <Button
              className={classes.buttonOutline}
              fullWidth
              onClick={handleRejectNovelty}
            >
              Rechazar novedad
            </Button>
            <Button
              className={classes.button}
              fullWidth
              onClick={handleApproveNovelty}
            >
              Aprobar novedad
            </Button>
          </Flex>
        </Grid.Col>
      )}
    </>
  );
};

export default Novelty;
