import React from "react";
import { Text, Grid, Button, Flex, Box, Badge, Textarea } from "@mantine/core";
import AccordionService from "./accordionService.component";
import Costs from "./costs.component";
import AidValueCard from "./aidValueCard.component";
import InfoAid from "./infoAid.component";
import { FaBell } from "react-icons/fa";

import Novelty from "./novelty.component";
import SeizureCard from "./seizureCard.component";
import ModalUnionApprove from "./modalUnionApprove.component";
import { Expenses } from "./expenses.component";
import moment from "moment";

const textStyles = {
  fontSize: "1.5rem",
  "@media print": {
    fontSize: "1.2rem",
  },
};

const labelTextStyles = {
  "@media print": {
    fontSize: "0.8rem",
  },
};

const totalTextStyles = {
  "@media print": {
    fontSize: "1rem",
  },
};

export const ReservationDetailsComponent = ({
  data,
  utils,
  classes,
  GlobalCard,
  StepperComponent,
  ServiceDetailCard,
  ModalComponent,
  isHistorical,
  handleApproveTrip,
  modalText,
  userData,
  spliceServices,
  filterByBeneficiaryType,
  allCosts,
  reservationData,
  validationData,
  handleRejectNovelty,
  handleApproveNovelty,
  openedUnion,
  closeUnion,
  formApproveUnion,
  formCheapest,
  modalContent,
  opened,
  isPreApprover,
  isMd,
  navigateToHistorical,
  printWindow,
  handleRejectTrip,
  handleApproveTripAction,
  handleSendApproveAction,
  navigateToEditTravel,
  handleDeleteTravelAction,
  close,
  openedReject,
  closeReject,
  formRejectTravel,
  openReject,
  disableButton,
}) => {
  const {
    Id,
    Trm,
    CodigoEmpleadoViajero,
    NombreEmpleadoViajero,
    ViajeTrayecto,
    codigoElementoPresupuestal,
    descripcion,
    ViajeGasto,
    ComisionAlias,
    TipoViajeAlias,
    TotalGastos,
    TotalViaticos,
    TotalBpo,
    totalLiquidacion,
    totalViaticosUsd,
    totalGastosUsd,
    totalViajeUsd,
    descricionEmergencia,
    Multiviaje,
    TipoProceso,
    TipoViajeId,
    totalDias,
    TieneViaticoPermanente,
    CodigoEmpleadoaprobadorPrevio,
    CodigoEmpleadoAprobador,
    ViaticoInt,
    FueraPolitica,
  } = data?.travelLiquidatorData || {};

  const initialDate = ViajeTrayecto?.[0]?.FechaInicio;
  const endDate = ViajeTrayecto?.[ViajeTrayecto.length - 1]?.FechaFin;

  const totalDays =
    TipoViajeId === 7
      ? `${totalDias} Días`
      : utils.numDays(
          ViajeTrayecto?.[0]?.FechaInicio,
          ViajeTrayecto?.[ViajeTrayecto.length - 1]?.FechaFin
        );

  const isAid = TipoViajeAlias === "TVAMBULATORIOS";

  const isInternationalFlight =
    data?.travelLiquidatorData?.TipoViajeAlias === "TVINTERNCL";

  const isApprovedTravel =
    CodigoEmpleadoAprobador === userData.datosUsuario.codigoEmpleado;

  const hasPreApproverTravel =
    CodigoEmpleadoaprobadorPrevio?.length > 0 || false;

  const infoAidProps = {
    data: data?.travelLiquidatorData,
    extraData: data?.travelStatus,
    dataAid: data?.dataAid,
    filterByBeneficiaryType,
    utils,
  };

  const isManagerAux =
    TipoProceso === 4 &&
    data?.travelStatus?.funcionarioIdGestor ===
      userData?.datosUsuario?.codigoEmpleado;

  const dateOfApproved = data?.travelStatus?.listaEstados
    ?.filter(
      (estado) =>
        estado.paramFkEstadoViaje === "APROBADO" ||
        estado.paramFkEstadoViaje === "CONFIRMADO"
    )
    .sort((a, b) => b.id - a.id);

  const seizureCardProps = { data: data?.seizureInfo, classes, utils };

  const accordionServiceProps = {
    data: spliceServices().services,
    totalDays,
    validationData,
    reservationData,
    specialLandTransport: data?.specialLandTransport,
    GlobalCard,
    utils,
  };

  const costProps = {
    utils,
    data: allCosts(),
    isInternational: isInternationalFlight,
    ModalComponent,
    isMd,
  };

  const aidValueCardProps = {
    utils,
    liquidator: data?.travelLiquidatorData,
    dataAid: data?.dataAid,
    ModalComponent,
    filterByBeneficiaryType,
  };

  return (
    <>
      <ModalComponent
        title="Alerta"
        opened={opened}
        onClose={close}
        closeOnClickOutside={modalContent.closeOnClickOutside}
      >
        <Text ta="center" fw={700} color="#004236">
          {modalContent.content}
        </Text>
        {modalContent.content === modalText["didNotChooseTheCheapest"] && (
          <Textarea
            {...formCheapest.getInputProps("justification")}
            label="Motivo"
            minRows={4}
            placeholder="Ingrese el motivo"
            classNames={{
              input: classes.input,
              label: classes.labelSecondary,
            }}
          />
        )}
        {modalContent.showButtons && (
          <Flex gap={16} my={12}>
            <Button
              onClick={modalContent.onClick}
              fullWidth
              className={classes.button}
            >
              {modalContent.buttonName.length > 0
                ? modalContent.buttonName
                : "Aceptar"}
            </Button>
            <Button onClick={close} fullWidth className={classes.buttonOutline}>
              Cancelar
            </Button>
          </Flex>
        )}
      </ModalComponent>
      <ModalUnionApprove
        form={formApproveUnion}
        opened={openedUnion}
        onClose={closeUnion}
        handleApprove={handleApproveTrip}
        item={data?.travelStatus}
        ModalComponent={ModalComponent}
        classes={classes}
      />
      <ModalComponent
        opened={openedReject}
        onClose={closeReject}
        title="Alerta"
      >
        <Text ta="center" fw={700} color="#004236">
          {TipoProceso === 4 ? "El auxilio" : "El viaje"} {Id} será rechazado.
          Ingrese la observación o razón del rechazo
        </Text>
        <Textarea
          value={formRejectTravel.values.obsAnulacion}
          onChange={(e) =>
            formRejectTravel.setFieldValue("obsAnulacion", e.target.value)
          }
          label="Observación"
          placeholder="Escriba aquí su observación"
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
          mt={12}
        />

        <Flex gap={16} my={12}>
          <Button
            onClick={handleRejectTrip}
            fullWidth
            className={classes.button}
            disabled={!formRejectTravel.isValid()}
          >
            Aceptar
          </Button>
          <Button
            onClick={closeReject}
            fullWidth
            className={classes.buttonOutline}
          >
            Cancelar
          </Button>
        </Flex>
      </ModalComponent>
      <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
        {data?.travelStatus?.fkEstadoViaje === "GUARDADO" && (
          <StepperComponent servicesData={data?.travelLiquidatorData} />
        )}
        <Grid py={16}>
          <Grid.Col span="auto">
            <Text size={24} fw={700} color="#004236">
              Detalles Reserva
            </Text>
          </Grid.Col>
          <Grid.Col span="auto" ta="right">
            <Text size={24} fw={700} color="#004236">
              Estado
              <Text span c="orange" inherit>
                {` ${
                  data?.travelStatus?.listaEstados?.find(
                    (status) =>
                      status.paramFkEstadoViaje ===
                      data?.travelStatus?.fkEstadoViaje
                  )?.paramNombreEstado
                }`}
              </Text>
            </Text>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col
            xs={12}
            md={9}
            sx={{
              "@media print": {
                flexBasis: "70%",
                maxWidth: "70%",
              },
            }}
          >
            <ServiceDetailCard
              showTotal
              totalDays={totalDays}
              showTotalDays
              gridStyles={{
                ".mantine-Grid-col": {
                  "@media print": {
                    flexBasis: "33%",
                    maxWidth: "33%",
                  },
                },
              }}
            >
              {TipoProceso === 4 ? (
                <>
                  <InfoAid {...infoAidProps} />
                  {data?.travelLiquidatorData?.ViajeTrayecto?.[0]?.auxilioMedico
                    ?.ObservacionDestinoPena && (
                    <Grid.Col pt={0}>
                      <Box>
                        <Text size="sm" fw={700} color="#004236">
                          Justificación PENA:
                        </Text>
                        <Text size="sm">
                          {
                            data?.travelLiquidatorData?.ViajeTrayecto?.[0]
                              ?.auxilioMedico?.ObservacionDestinoPena
                          }
                        </Text>
                      </Box>
                    </Grid.Col>
                  )}
                </>
              ) : (
                <>
                  {TipoViajeAlias === "TVEMERGENCIA" && (
                    <Grid.Col
                      span={12}
                      sx={{
                        "@media print": {
                          flexBasis: "100% !important",
                          maxWidth: "100% !important",
                        },
                      }}
                    >
                      <Badge
                        sx={{
                          backgroundColor: "#ed7703",
                          color: "#fff",
                        }}
                      >
                        Viaje de emergencia
                      </Badge>
                    </Grid.Col>
                  )}
                  {TieneViaticoPermanente && (
                    <Grid.Col
                      span={12}
                      sx={{
                        "@media print": {
                          flexBasis: "100% !important",
                          maxWidth: "100% !important",
                        },
                      }}
                    >
                      <Badge
                        sx={{
                          backgroundColor: "#ed7703",
                          color: "#fff",
                        }}
                      >
                        Viaje con viático permanente
                      </Badge>
                    </Grid.Col>
                  )}
                  {FueraPolitica && (
                    <Grid.Col
                      span={12}
                      sx={{
                        "@media print": {
                          flexBasis: "100% !important",
                          maxWidth: "100% !important",
                        },
                      }}
                    >
                      <Badge
                        sx={{
                          backgroundColor: "#ed7703",
                          color: "#fff",
                        }}
                      >
                        Fuera de política
                      </Badge>
                    </Grid.Col>
                  )}
                  <Grid.Col xs={12} md={4}>
                    <Box>
                      <Text size="sm" fw={700} color="#004236">
                        Viajero:
                      </Text>
                      <Text size="sm">{NombreEmpleadoViajero}</Text>
                    </Box>
                    <Box my={12}>
                      <Text size="sm" fw={700} color="#004236">
                        T. Viaje / T. Comisión:
                      </Text>
                      <Text size="sm">
                        {TipoViajeAlias} / {ComisionAlias}
                      </Text>
                    </Box>
                    {Multiviaje ? (
                      ViajeTrayecto.map((item, index) => (
                        <Box key={index}>
                          <Box my={12}>
                            <Text size="sm" fw={700} color="#004236">
                              Ciudad Origen:
                            </Text>
                            <Text size="sm">{item.nombreCiudadOrigen}</Text>
                          </Box>
                          <Box my={12}>
                            <Text size="sm" fw={700} color="#004236">
                              Fecha Inicio:
                            </Text>
                            <Text size="sm">
                              {utils.parseDateLocaleFormat(item.FechaInicio)}
                            </Text>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Box my={12}>
                        <Text size="sm" fw={700} color="#004236">
                          Ciudad Origen:
                        </Text>
                        <Text size="sm">
                          {ViajeTrayecto?.[0]?.nombreCiudadOrigen}
                        </Text>
                      </Box>
                    )}
                    <Box>
                      <Text size="sm" fw={700} color="#004236">
                        Id Viaje
                      </Text>
                      <Text size="sm">{Id}</Text>
                    </Box>
                  </Grid.Col>
                  <Grid.Col xs={12} md={4}>
                    <Box>
                      <Text size="sm" fw={700} color="#004236">
                        Cod. Empleado:
                      </Text>
                      <Text size="sm">{CodigoEmpleadoViajero}</Text>
                    </Box>
                    <Box my={12}>
                      <Text size="sm" fw={700} color="#004236">
                        Fecha Inicio Viaje:
                      </Text>
                      <Text size="sm" transform="capitalize">
                        {utils.parseDateLocaleFormat(initialDate)}
                      </Text>
                    </Box>
                    {Multiviaje ? (
                      ViajeTrayecto.map((item, index) => (
                        <Box key={index}>
                          <Box my={12}>
                            <Text size="sm" fw={700} color="#004236">
                              Ciudad Destino:
                            </Text>
                            <Text size="sm">{item.nombreCiudadDestino}</Text>
                          </Box>
                          <Box my={12}>
                            <Text size="sm" fw={700} color="#004236">
                              Fecha Fin:
                            </Text>
                            <Text size="sm">
                              {utils.parseDateLocaleFormat(item.FechaFin)}
                            </Text>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Box my={12}>
                        <Text size="sm" fw={700} color="#004236">
                          Ciudad Destino:
                        </Text>
                        <Text size="sm">
                          {ViajeTrayecto?.[0]?.nombreCiudadDestino}
                        </Text>
                      </Box>
                    )}

                    <Box>
                      <Text size="sm" fw={700} color="#004236">
                        Objetivo del viaje:
                      </Text>
                      <Text size="sm">{descripcion}</Text>
                    </Box>
                  </Grid.Col>
                  <Grid.Col xs={12} md={4}>
                    <Box>
                      <Text size="sm" fw={700} color="#004236">
                        Centro de costo:
                      </Text>
                      <Text size="sm">{codigoElementoPresupuestal}</Text>
                    </Box>
                    <Box my={12}>
                      <Text size="sm" fw={700} color="#004236">
                        Fecha Fin Viaje:
                      </Text>
                      <Text size="sm" transform="capitalize">
                        {utils.parseDateLocaleFormat(endDate)}
                      </Text>
                    </Box>
                    <Box my={12}>
                      <Text size="sm" fw={700} color="#004236">
                        Gestor del viaje:
                      </Text>
                      <Text size="sm">
                        {data?.travelStatus?.funcionarioNombreGestor
                          ? data?.travelStatus?.funcionarioNombreGestor
                          : "N/A"}
                      </Text>
                    </Box>
                    <Box>
                      <Text size="sm" fw={700} color="#004236">
                        Tiene que viajar:
                      </Text>
                      <Text size="sm">Sí</Text>
                    </Box>
                  </Grid.Col>
                  {TipoViajeAlias === "TVEMERGENCIA" && (
                    <Grid.Col xs={12} md={4}>
                      <Box>
                        <Text size="sm" fw={700} color="#004236">
                          Objetivo de la emergencia:
                        </Text>
                        <Text size="sm">{descricionEmergencia}</Text>
                      </Box>
                    </Grid.Col>
                  )}
                </>
              )}
            </ServiceDetailCard>
            {data?.travelStatus?.novedades?.length > 0 &&
              data?.travelStatus?.novedades?.map((item, index) => (
                <ServiceDetailCard
                  totalLabel="Total"
                  title={`Novedad / ${utils.parseDateLocaleFormat(
                    item?.FechaNovedad
                  )}`}
                  totalCop={utils.formatter.format(
                    item?.ValorTotalNovedadesCOP
                  )}
                  totalUsd={
                    !isInternationalFlight
                      ? 0
                      : utils.formatterUSD.format(item?.ValorTotalNovedadesUSD)
                  }
                  key={index}
                >
                  <Novelty
                    item={item}
                    isMulti={Multiviaje}
                    isInternational={isInternationalFlight}
                    isApprover={isApprovedTravel}
                    handleApproveNovelty={() => handleApproveNovelty(item)}
                    handleRejectNovelty={() => handleRejectNovelty(item)}
                    classes={classes}
                    utils={utils}
                  />
                </ServiceDetailCard>
              ))}
            {data?.seizureInfo?.length > 0 && (
              <ServiceDetailCard title="Con pago a un tercero">
                <SeizureCard {...seizureCardProps} />
              </ServiceDetailCard>
            )}
            {(Object.keys(spliceServices().services).length > 0 ||
              data?.specialLandTransport?.length > 0) && (
              <ServiceDetailCard
                totalLabel="Total"
                title="Servicios"
                totalCop={utils.formatter.format(TotalBpo)}
                totalUsd={0}
              >
                <Grid.Col>
                  <Flex align="center" justify="space-between">
                    <Text size="sm" fw={700} color="#004236">
                      Concepto
                    </Text>
                    <Text size="sm" fw={700} color="#004236">
                      Valor Total
                    </Text>
                  </Flex>
                </Grid.Col>

                <AccordionService {...accordionServiceProps} />
              </ServiceDetailCard>
            )}
            {!!allCosts()?.length && (
              <ServiceDetailCard
                totalLabel="Total"
                title="Viáticos"
                totalCop={
                  isInternationalFlight
                    ? utils.formatter.format(ViaticoInt)
                    : utils.formatter.format(TotalViaticos)
                }
                totalUsd={
                  !isInternationalFlight
                    ? 0
                    : utils.formatterUSD.format(totalViaticosUsd)
                }
              >
                <Grid.Col>
                  <Grid>
                    <Grid.Col span={6}>
                      <Text size="sm" fw={700} color="#004236">
                        Concepto
                      </Text>
                    </Grid.Col>

                    <Grid.Col
                      span={3}
                      sx={{
                        display: !isInternationalFlight && "none",
                      }}
                    >
                      <Text ta="right" size="sm" fw={700} color="#004236">
                        Dólares
                      </Text>
                    </Grid.Col>

                    <Grid.Col span={isInternationalFlight ? 3 : 6}>
                      <Text size="sm" ta="right" fw={700} color="#004236">
                        Pesos
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
                <Costs {...costProps} />
              </ServiceDetailCard>
            )}
            {!!ViajeGasto?.length && !isAid && (
              <ServiceDetailCard
                totalLabel="Total"
                title="Otros gastos"
                totalCop={utils.formatter.format(TotalGastos)}
                totalUsd={
                  !isInternationalFlight
                    ? 0
                    : utils.formatterUSD.format(totalGastosUsd)
                }
              >
                <Expenses
                  data={ViajeGasto}
                  utils={utils}
                  isInternationalFlight={isInternationalFlight}
                />
              </ServiceDetailCard>
            )}
            {TipoProceso === 4 && (
              <>
                <ServiceDetailCard
                  totalLabel="Total"
                  title="Valor auxilio ambulatorio"
                  totalCop={utils.formatter.format(
                    data?.travelLiquidatorData?.ViajeTrayecto?.[0]
                      ?.auxilioMedico?.ValorTotalAuxilio
                  )}
                >
                  <AidValueCard {...aidValueCardProps} />
                </ServiceDetailCard>
                <ServiceDetailCard title="Fechas del auxilio ambulatorio">
                  <Grid.Col>
                    <Grid columns={5} fz="sm" fw={700}>
                      <Grid.Col span={1}>
                        <Text color="#004236">Nombre</Text>
                      </Grid.Col>

                      <Grid.Col span={1}>
                        <Text color="#004236">Tipo</Text>
                      </Grid.Col>

                      <Grid.Col span={1}>
                        <Text color="#004236">Fecha inicial</Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text color="#004236">Fecha final</Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text color="#004236">Retorno</Text>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                  <Grid.Col>
                    <Grid columns={5} fz="sm">
                      <Grid.Col span={1}>
                        <Text>
                          {`${filterByBeneficiaryType(
                            data?.dataAid,
                            "BENEFICIARIO",
                            "nombres"
                          )} ${filterByBeneficiaryType(
                            data?.dataAid,
                            "BENEFICIARIO",
                            "apellidos"
                          )}`}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text>Beneficiario</Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text>
                          {utils.parseDateLocaleFormat(
                            data?.travelLiquidatorData?.ViajeTrayecto?.[0]
                              ?.auxilioMedico?.PerBeneficiario?.fechaInicioViaje
                          )}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text>
                          {utils.parseDateLocaleFormat(
                            data?.travelLiquidatorData?.ViajeTrayecto?.[0]
                              ?.auxilioMedico?.PerBeneficiario?.fechaFinViaje
                          )}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={1}>
                        <Text>
                          {data?.travelLiquidatorData?.ViajeTrayecto?.[0]
                            ?.auxilioMedico?.PerBeneficiario?.retorno === false
                            ? "No"
                            : "Sí"}
                        </Text>
                      </Grid.Col>
                    </Grid>
                  </Grid.Col>
                  {data?.travelLiquidatorData?.ViajeTrayecto?.[0]?.auxilioMedico
                    ?.Acompanante && (
                    <Grid.Col>
                      <Grid columns={5} fz="sm">
                        <Grid.Col span={1}>
                          <Text>
                            {`${filterByBeneficiaryType(
                              data?.dataAid,
                              "ACOMPANANTE",
                              "nombres"
                            )} ${filterByBeneficiaryType(
                              data?.dataAid,
                              "ACOMPANANTE",
                              "apellidos"
                            )}`}
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <Text>Acompañante</Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <Text>
                            {utils.parseDateLocaleFormat(
                              data?.travelLiquidatorData?.ViajeTrayecto?.[0]
                                ?.auxilioMedico?.PerAcompanante
                                ?.fechaInicioViaje
                            )}
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <Text>
                            {utils.parseDateLocaleFormat(
                              data?.travelLiquidatorData?.ViajeTrayecto?.[0]
                                ?.auxilioMedico?.PerAcompanante?.fechaFinViaje
                            )}
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <Text>
                            {data?.travelLiquidatorData?.ViajeTrayecto?.[0]
                              ?.auxilioMedico?.PerAcompanante?.retorno === false
                              ? "No"
                              : "Sí"}
                          </Text>
                        </Grid.Col>
                      </Grid>
                    </Grid.Col>
                  )}
                </ServiceDetailCard>
                {!!ViajeGasto?.length && isAid && (
                  <ServiceDetailCard
                    totalLabel="Total"
                    title="Otros gastos"
                    totalCop={utils.formatter.format(TotalGastos)}
                    totalUsd={
                      !isInternationalFlight
                        ? 0
                        : utils.formatterUSD.format(totalGastosUsd)
                    }
                  >
                    <Expenses
                      data={ViajeGasto}
                      utils={utils}
                      isInternationalFlight={isInternationalFlight}
                    />
                  </ServiceDetailCard>
                )}
              </>
            )}
            <Box>
              <Text size="lg" color="#004236" weight={700} sx={totalTextStyles}>
                Valor total
              </Text>
              {isAid && (
                <Flex
                  bg="#cbd300"
                  py={10}
                  px={15}
                  sx={{ borderRadius: "0.5rem" }}
                  mt={20}
                  justify="space-between"
                  align="center"
                >
                  <Text
                    size="lg"
                    color="#004236"
                    fw={700}
                    ml="auto"
                    sx={totalTextStyles}
                  >
                    {utils.formatter.format(ViajeTrayecto?.[0]?.TotalAveLiqCop)}
                  </Text>
                </Flex>
              )}
              <Flex
                bg={isAid ? "#000" : "#cbd300"}
                py={10}
                px={15}
                sx={{ borderRadius: "0.5rem" }}
                my={20}
                justify="space-between"
                align="center"
              >
                {TipoViajeAlias === "TVINTERNCL" && (
                  <Text size="lg" color="#004236" fw={700} sx={totalTextStyles}>
                    Total (Viáticos + Otros gastos)
                  </Text>
                )}
                <Text
                  size="lg"
                  color={isAid ? "#fff" : "#004236"}
                  fw={700}
                  ml="auto"
                  sx={totalTextStyles}
                >
                  {TipoViajeAlias === "TVINTERNCL"
                    ? `${utils.currenciesSum(
                        [totalGastosUsd, totalViaticosUsd],
                        true
                      )} + ${utils.currenciesSum([
                        TotalGastos,
                        isInternationalFlight ? ViaticoInt : TotalViaticos,
                      ])}`
                    : utils.formatter.format(totalLiquidacion)}
                </Text>
              </Flex>
            </Box>
          </Grid.Col>
          <Grid.Col
            xs={12}
            md={3}
            sx={{
              "@media print": {
                flexBasis: "30%",
                maxWidth: "30%",
              },
            }}
          >
            <GlobalCard>
              <Flex justify="center" align="center" direction="column">
                <Text size="lg" fw={700} color="#004236">
                  Detalle Liquidación
                </Text>
                <Flex
                  justify="center"
                  align="center"
                  direction="row"
                  wrap="wrap"
                  gap={8}
                >
                  <Text size="md" sx={labelTextStyles}>
                    Total días:
                  </Text>
                  <Text sx={{ fontSize: "1.5rem" }} fw={700} color="#004236">
                    {TipoViajeAlias === "TVTURNOS" ? "0" : totalDays}
                  </Text>
                </Flex>
                {isAid ? (
                  <Text size="md" ta="center" sx={labelTextStyles}>
                    Total Anticipo: <br />
                    (Auxilio ambulatorio + otros gastos)
                  </Text>
                ) : (
                  <Text size="md" ta="center" sx={labelTextStyles}>
                    Valor Anticipo: <br />
                    (Viáticos + Servicios de viajes)
                  </Text>
                )}
                {isAid && (
                  <Text sx={textStyles} fw={700} color="#004236">
                    {utils.formatter.format(ViajeTrayecto?.[0]?.TotalAveLiqCop)}
                  </Text>
                )}
                {isAid && (
                  <Text size="md" mt={8} sx={labelTextStyles}>
                    Valor total viaje
                  </Text>
                )}
                <Text sx={textStyles} fw={700} color="#004236">
                  {utils.formatter.format(totalLiquidacion)}
                </Text>
                {TipoViajeAlias === "TVINTERNCL" && (
                  <Flex
                    gap="xs"
                    justify="center"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                    <Text
                      size="xs"
                      fw={700}
                      color="#004236"
                    >{`TRM (${Trm})`}</Text>
                    <Text sx={textStyles} fw={700} color="#004236">
                      {utils.formatterUSD.format(totalViajeUsd)}
                    </Text>
                  </Flex>
                )}
                {data?.travelStatus?.fkEstadoViaje === "GUARDADO" && (
                  <>
                    {!(isInternationalFlight && Multiviaje) && (
                      <Button
                        mb={10}
                        fullWidth
                        className={classes.buttonOutline}
                        onClick={(event) => {
                          event.currentTarget.disabled = true;
                          navigateToEditTravel();
                        }}
                        sx={{
                          "@media print": {
                            display: "none",
                          },
                        }}
                      >
                        Editar Viaje
                      </Button>
                    )}
                    <Button
                      fullWidth
                      className={classes.buttonOutline}
                      onClick={handleDeleteTravelAction}
                      sx={{
                        "@media print": {
                          display: "none",
                        },
                      }}
                    >
                      Eliminar
                    </Button>
                  </>
                )}

                <Box bg="#F6F6F6" w="100%" p={10} my={10}>
                  <Text size="sm" ta="left">
                    Aprobador
                  </Text>
                  <Text size="sm" fw={700} color="#004236" ta="left">
                    {data?.travelStatus?.funcionarioNombreAprobador}
                  </Text>
                </Box>
                {isManagerAux && dateOfApproved?.length > 0 && (
                  <Box bg="#F6F6F6" w="100%" p={10} my={10}>
                    <Text size="sm" ta="left">
                      El auxilio fue aprobado el
                    </Text>
                    <Text size="sm" fw={700} color="#004236" ta="left">
                      {moment(dateOfApproved?.[0]?.fechaCreacion)
                        .locale("es")
                        .format("DD-MMM-YYYY hh:mm A")}
                    </Text>
                  </Box>
                )}
                {data?.travelStatus?.funcionarioNombreAprobadorPrevio && (
                  <Box bg="#F6F6F6" w="100%" p={10} mb={10}>
                    <Text size="sm" ta="left">
                      Aprobador previo
                    </Text>
                    <Text size="sm" fw={700} color="#004236" ta="left">
                      {data?.travelStatus?.funcionarioNombreAprobadorPrevio}
                    </Text>
                  </Box>
                )}
                {data?.travelStatus?.fkEstadoViaje !== "GUARDADO" ? null : (
                  <Button
                    fullWidth
                    mb={10}
                    className={classes.button}
                    onClick={(event) => {
                      event.currentTarget.disabled = true;
                      handleSendApproveAction();
                    }}
                    sx={{
                      "@media print": {
                        display: "none",
                      },
                    }}
                    disabled={disableButton}
                  >
                    Enviar Aprobar
                  </Button>
                )}

                {data?.travelStatus?.fkEstadoViaje === "PENDAPROBO" ||
                data?.travelStatus?.fkEstadoViaje === "PRAPROBADO"
                  ? ((isApprovedTravel && !hasPreApproverTravel) ||
                      (isApprovedTravel &&
                        hasPreApproverTravel &&
                        data?.travelStatus?.fkEstadoViaje === "PRAPROBADO") ||
                      (isPreApprover &&
                        data?.travelStatus?.fkEstadoViaje ===
                          "PENDAPROBO")) && (
                      <>
                        <Button
                          mb={10}
                          fullWidth
                          className={classes.button}
                          onClick={(event) => {
                            event.currentTarget.disabled = true;
                            handleApproveTripAction();
                          }}
                          sx={{
                            "@media print": {
                              display: "none",
                            },
                          }}
                        >
                          Aprobar Viaje
                        </Button>
                        <Button
                          mb={10}
                          fullWidth
                          className={classes.button}
                          onClick={openReject}
                          sx={{
                            "@media print": {
                              display: "none",
                            },
                          }}
                        >
                          Rechazar Viaje
                        </Button>
                      </>
                    )
                  : null}
                {isHistorical && (
                  <Button
                    fullWidth
                    className={classes.buttonOutline}
                    mb={10}
                    onClick={navigateToHistorical}
                    sx={{
                      "@media print": {
                        display: "none",
                      },
                    }}
                  >
                    Volver al histórico
                  </Button>
                )}
                <Button
                  fullWidth
                  className={classes.button}
                  onClick={printWindow}
                  data-testid="print-button"
                  sx={{
                    "@media print": {
                      display: "none",
                    },
                  }}
                >
                  Imprimir Viaje
                </Button>
              </Flex>
            </GlobalCard>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};
