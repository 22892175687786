import React from "react";
import { Grid, Text } from "@mantine/core";

export const Expenses = ({ data, utils, isInternationalFlight }) => {
  return (
    <>
      <Grid.Col>
        <Grid>
          <Grid.Col span={6}>
            <Text size="sm" fw={700} color="#004236">
              Concepto
            </Text>
          </Grid.Col>

          <Grid.Col span={3}>
            <Text
              sx={{
                display: !isInternationalFlight && "none",
              }}
              ta="right"
              size="sm"
              fw={700}
              color="#004236"
            >
              Dólares
            </Text>
          </Grid.Col>

          <Grid.Col span={3}>
            <Text size="sm" ta="right" fw={700} color="#004236">
              Pesos
            </Text>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      {data?.map((cost, index) => (
        <Grid.Col
          sx={{
            background: index % 2 !== 0 ? "transparent" : "#F5F5F5",
          }}
          p={15}
          key={index}
        >
          <Grid>
            <Grid.Col span={isInternationalFlight ? 3 : 6}>
              <Text size="sm">{cost?.Nombre}</Text>
            </Grid.Col>
            <Grid.Col
              offset={3}
              span={3}
              sx={{
                display: !isInternationalFlight && "none",
              }}
            >
              <Text size="sm" ta="right">
                {cost?.TipoMonedaId === "USD"
                  ? utils.formatterUSD.format(cost?.Valor)
                  : "$0  USD"}
              </Text>
            </Grid.Col>
            <Grid.Col span={isInternationalFlight ? 3 : 6}>
              <Text size="sm" ta="right">
                {cost?.TipoMonedaId === "USD"
                  ? "$0  COP"
                  : utils.formatter.format(cost?.Valor)}
              </Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      ))}
    </>
  );
};
