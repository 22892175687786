import { Grid, Text, Flex, Divider, Box } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { useState } from "react";
import InfoIcon from "./infoIcon.component";

const Costs = ({ utils, data, isInternational, ModalComponent, isMd }) => {
  const [details, setDetails] = useState({
    total: 0,
    details: [],
    currency: "",
  });
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <ModalComponent title="Información" opened={opened} onClose={close}>
        <Flex
          align="center"
          justify="center"
          direction="column"
          fz={20}
          fw={700}
          c="#004236"
        >
          <Text>Total</Text>
          <Text>
            {details.currency === "COP"
              ? utils.formatter.format(details.total)
              : `${utils.formatterUSD.format(details.total)}`}
          </Text>
        </Flex>
        <Divider my={12} />
        <Flex ta="center" fw={700}>
          <Text sx={{ flex: 1 }}>Origen</Text>
          <Text sx={{ flex: 1 }}>Destino</Text>
        </Flex>
        {details.details.map((detail, index) => (
          <Flex key={index} bg={index % 2 !== 0 ? "transparent" : "#F5F5F5"}>
            <Box sx={{ flex: 1 }} ta="center">
              <Text>{detail.originCity}</Text>
              <Text>{utils.parseDateLocaleFormat(detail.initialDate)}</Text>
            </Box>
            <Box sx={{ flex: 1 }} ta="center">
              <Text>{detail.destinationCity}</Text>
              <Text>{utils.parseDateLocaleFormat(detail.finalDate)}</Text>
            </Box>
          </Flex>
        ))}
      </ModalComponent>
      {data.map((cost, index) => (
        <Grid.Col
          sx={{
            background: index % 2 !== 0 ? "transparent" : "#F5F5F5",
          }}
          p={15}
          key={index}
        >
          <Grid>
            <Grid.Col span={3}>
              <Text size="sm">{cost?.alias}</Text>
            </Grid.Col>
            <Grid.Col span={3}>
              <div style={{ float: isMd ? "right" : "none" }}>
                <InfoIcon
                  onClick={() => {
                    open();
                    setDetails({
                      total: cost?.value,
                      details: cost?.details,
                      currency: cost?.currency,
                    });
                  }}
                  small
                />
              </div>
            </Grid.Col>
            <Grid.Col
              span={3}
              sx={{
                display: !isInternational && "none",
              }}
            >
              <Text size="sm" ta="right">
                {cost?.currency === "USD"
                  ? utils.formatterUSD.format(cost?.value)
                  : "$0 USD"}
              </Text>
            </Grid.Col>
            <Grid.Col span={isInternational ? 3 : 6}>
              <Text size="sm" ta="right">
                {cost?.currency === "USD"
                  ? "$0 COP"
                  : utils.formatter.format(cost?.value)}
              </Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      ))}
    </>
  );
};

export default Costs;
