import { Accordion, Grid, Text, Badge, Flex } from "@mantine/core";

const AccordionService = ({
  data,
  totalDays,
  validationData,
  reservationData,
  specialLandTransport,
  GlobalCard,
  utils,
}) => {
  const filterReservation = reservationData?.filter(
    ({ servicio }) => servicio === "AVION"
  );

  return (
    <Grid.Col p={15}>
      {!!data?.HOTEL?.dataService?.length && (
        <Accordion defaultValue="hotel">
          <Accordion.Item value="hotel">
            <Accordion.Control fz="sm" p={0}>
              Hotel
            </Accordion.Control>

            <Accordion.Panel>
              {data?.HOTEL?.dataService?.map((item, index) => (
                <GlobalCard key={index}>
                  <Grid fz="sm">
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Nombre Hotel
                      </Text>
                      <Text>{item.nameHotel}</Text>
                    </Grid.Col>
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Ciudad Origen
                      </Text>
                      <Text>{item.nombreCiudadOrigen}</Text>
                    </Grid.Col>
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Ciudad Destino
                      </Text>
                      <Text>{item.nombreCiudadDestino}</Text>
                    </Grid.Col>
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Fecha Ingreso
                      </Text>
                      <Text>
                        {utils.parseDateLocaleFormat(
                          JSON.parse(item.descripcionServicio)?.fechaInicio
                        )}
                      </Text>
                    </Grid.Col>
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Fecha Salida
                      </Text>
                      <Text>
                        {utils.parseDateLocaleFormat(
                          JSON.parse(item.descripcionServicio)?.fechaFin
                        )}
                      </Text>
                    </Grid.Col>
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Noches de hospedaje
                      </Text>
                      <Text>{`${utils.numDays(
                        JSON.parse(item.descripcionServicio)?.fechaInicio,
                        JSON.parse(item.descripcionServicio)?.fechaFin,
                        true
                      )}`}</Text>
                    </Grid.Col>
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Valor del hotel
                      </Text>
                      <Text>{utils.formatter.format(item.total)}</Text>
                    </Grid.Col>
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Tarifa Por Noche
                      </Text>
                      <Text>
                        {utils.formatter.format(
                          utils.valuePerNight(
                            item.total,
                            JSON.parse(item.descripcionServicio)?.fechaInicio,
                            JSON.parse(item.descripcionServicio)?.fechaFin,
                            true
                          )
                        )}
                      </Text>
                    </Grid.Col>

                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Dirección
                      </Text>
                      <Text>
                        {JSON.parse(item.descripcionServicio)?.address?.split(
                          "|"
                        )?.[0]?.length > 1
                          ? JSON.parse(item.descripcionServicio)?.address
                          : "No registra"}
                      </Text>
                    </Grid.Col>
                  </Grid>
                </GlobalCard>
              ))}
            </Accordion.Panel>
          </Accordion.Item>
          <Text ta="right">{utils.formatter.format(data.HOTEL.total)}</Text>
        </Accordion>
      )}
      {!!data?.AVION?.dataService?.length && (
        <Accordion defaultValue="hotel">
          <Accordion.Item value="hotel">
            <Accordion.Control fz="sm" p={0}>
              Avion
            </Accordion.Control>
            <Accordion.Panel>
              <GlobalCard>
                {validationData
                  ? data?.AVION?.dataService?.map((item, index) =>
                      item.fligths.map((fligth, fligthIndex) => (
                        <Grid fz="sm" key={fligthIndex}>
                          {fligthIndex > 0 ? (
                            <Grid.Col xs={12}>
                              <Text
                                ta="center"
                                size="md"
                                fw={700}
                                color="#004236"
                              >
                                {item.fligths.length - 1 !== 0
                                  ? null
                                  : "Detalles del vuelo"}
                              </Text>
                            </Grid.Col>
                          ) : (
                            <Grid.Col xs={12}>
                              <Text
                                ta="center"
                                size="md"
                                fw={700}
                                color="#004236"
                              >
                                Detalles del vuelo
                              </Text>
                              <Badge
                                sx={{
                                  backgroundColor: "#ed7703",
                                  color: "#fff",
                                }}
                              >
                                {`N.º de reserva: ${
                                  !!filterReservation?.length
                                    ? filterReservation[index]?.codReserva
                                    : "PENDIENTE"
                                } `}
                              </Badge>
                            </Grid.Col>
                          )}
                          {!!filterReservation?.length && (
                            <Grid.Col>
                              <Badge
                                sx={{
                                  backgroundColor: "#ed7703",
                                  color: "#fff",
                                }}
                                ml={10}
                              >
                                {`N.º de vuelo: ${fligth?.flightNumber}`}
                              </Badge>
                            </Grid.Col>
                          )}
                          <Grid.Col xs={3}>
                            <Text fw={700} color="#004236">
                              Fecha Vuelo
                            </Text>
                            <Text>
                              {utils.parseDateLocaleFormat(
                                fligth.departureDateTime
                              )}
                            </Text>
                          </Grid.Col>
                          <Grid.Col xs={3}>
                            <Text fw={700} color="#004236">
                              Ciudad Origen
                            </Text>
                            <Text>{fligth.departureCity}</Text>
                          </Grid.Col>
                          <Grid.Col xs={3}>
                            <Text fw={700} color="#004236">
                              Ciudad Destino
                            </Text>
                            <Text>{fligth.arrivalCity}</Text>
                          </Grid.Col>
                          <Grid.Col xs={3}>
                            <Text fw={700} color="#004236">
                              Aerolinea
                            </Text>
                            <Text>{fligth.nameAirLine}</Text>
                          </Grid.Col>
                          <Grid.Col xs={3}>
                            <Text fw={700} color="#004236">
                              Hora Vuelo Salida
                            </Text>
                            <Text>
                              {utils.extractTime(
                                fligth.departureDateTime,
                                "hh:mm"
                              )}
                            </Text>
                          </Grid.Col>
                          <Grid.Col xs={3}>
                            <Text fw={700} color="#004236">
                              Hora Vuelo LLegada
                            </Text>
                            <Text>
                              {utils.extractTime(
                                fligth.arrivalDateTime,
                                "hh:mm"
                              )}
                            </Text>
                          </Grid.Col>

                          <Grid.Col xs={3}>
                            <Text fw={700} color="#004236">
                              Aeropuerto Salida
                            </Text>
                            <Text>{fligth.departureAirport}</Text>
                          </Grid.Col>
                          <Grid.Col xs={3}>
                            <Text fw={700} color="#004236">
                              Aeropuerto Llegada
                            </Text>
                            <Text>{fligth.arrivalAirport}</Text>
                          </Grid.Col>

                          {item.fligths.length - 1 !== fligthIndex ? null : (
                            <Grid.Col xs="auto">
                              <Text fw={700} color="#004236">
                                Valor vuelo
                              </Text>
                              <Text>
                                {utils.formatter.format(item.fares.totalAmount)}
                              </Text>
                            </Grid.Col>
                          )}
                        </Grid>
                      ))
                    )
                  : data?.AVION?.dataService?.map((fligth, fligthIndex) => (
                      <Grid fz="sm" key={fligthIndex}>
                        {fligthIndex > 0 ? (
                          <Grid.Col xs={12}>
                            <Text
                              ta="center"
                              size="md"
                              fw={700}
                              color="#004236"
                            >
                              Detalles del vuelo
                            </Text>
                          </Grid.Col>
                        ) : (
                          <Grid.Col xs={12}>
                            <Text
                              ta="center"
                              size="md"
                              fw={700}
                              color="#004236"
                            >
                              Detalles del vuelo
                            </Text>
                            <Badge
                              sx={{
                                backgroundColor: "#ed7703",
                                color: "#fff",
                              }}
                            >
                              {`N.º de reserva: ${
                                !!filterReservation?.length
                                  ? filterReservation[fligthIndex]?.codReserva
                                  : "PENDIENTE"
                              } `}
                            </Badge>
                          </Grid.Col>
                        )}
                        {!!filterReservation?.length && (
                          <Grid.Col>
                            <Badge
                              sx={{
                                backgroundColor: "#ed7703",
                                color: "#fff",
                              }}
                              ml={10}
                            >
                              {`N.º de vuelo: ${fligth?.flightNumber}`}
                            </Badge>
                          </Grid.Col>
                        )}
                        <Grid.Col xs={3}>
                          <Text fw={700} color="#004236">
                            Fecha Vuelo
                          </Text>
                          <Text>
                            {utils.parseDateLocaleFormat(
                              fligth.departureDateTime
                            )}
                          </Text>
                        </Grid.Col>
                        <Grid.Col xs={3}>
                          <Text fw={700} color="#004236">
                            Ciudad Origen
                          </Text>
                          <Text>{fligth.departureCity}</Text>
                        </Grid.Col>
                        <Grid.Col xs={3}>
                          <Text fw={700} color="#004236">
                            Ciudad Destino
                          </Text>
                          <Text>{fligth.arrivalCity}</Text>
                        </Grid.Col>
                        <Grid.Col xs={3}>
                          <Text fw={700} color="#004236">
                            Aerolinea
                          </Text>
                          <Text>{fligth.nameAirLine}</Text>
                        </Grid.Col>
                        <Grid.Col xs={3}>
                          <Text fw={700} color="#004236">
                            Hora Vuelo Salida
                          </Text>
                          <Text>
                            {utils.extractTime(
                              fligth.departureDateTime,
                              "hh:mm"
                            )}
                          </Text>
                        </Grid.Col>
                        <Grid.Col xs={3}>
                          <Text fw={700} color="#004236">
                            Hora Vuelo LLegada
                          </Text>
                          <Text>
                            {utils.extractTime(fligth.arrivalDateTime, "hh:mm")}
                          </Text>
                        </Grid.Col>

                        <Grid.Col xs={3}>
                          <Text fw={700} color="#004236">
                            Aeropuerto Salida
                          </Text>
                          <Text>{fligth.departureAirport}</Text>
                        </Grid.Col>
                        <Grid.Col xs={3}>
                          <Text fw={700} color="#004236">
                            Aeropuerto Llegada
                          </Text>
                          <Text>{fligth.arrivalAirport}</Text>
                        </Grid.Col>
                        {fligthIndex ===
                        data?.AVION?.dataService?.length - 1 ? (
                          <Grid.Col xs="auto">
                            <Text fw={700} color="#004236">
                              Valor vuelo
                            </Text>
                            <Text>
                              {utils.formatter.format(data?.AVION?.total)}
                            </Text>
                          </Grid.Col>
                        ) : null}
                      </Grid>
                    ))}
              </GlobalCard>
            </Accordion.Panel>
          </Accordion.Item>
          <Flex fz="sm" justify="space-between" pt={15}>
            <Text fw={700} color="#004236">
              Tenga en cuenta que a este valor se le debe adicionar $14.000 COP.
              Correspondientes a la tarifa administrativa.
            </Text>
            <Text>{utils.formatter.format(data?.AVION?.total)}</Text>
          </Flex>
        </Accordion>
      )}
      {!!data?.ALQVEH?.dataService?.length && (
        <Accordion defaultValue="vehicles">
          <Accordion.Item value="vehicles">
            <Accordion.Control fz="sm" p={0}>
              Alquiler Vehículo Internacional
            </Accordion.Control>

            <Accordion.Panel>
              {data?.ALQVEH?.dataService?.map((item, index) => {
                const description = JSON.parse(item.descripcionServicio);
                return (
                  <GlobalCard key={index}>
                    <Grid fz="sm">
                      <Grid.Col xs={4}>
                        <Text fw={700} color="#004236">
                          Dias de alquiler
                        </Text>
                        <Text>{`${totalDays}`}</Text>
                      </Grid.Col>
                      <Grid.Col xs={4}>
                        <Text fw={700} color="#004236">
                          Marca vehículo
                        </Text>
                        <Text>{item.nombreServicio}</Text>
                      </Grid.Col>
                      <Grid.Col xs={4}>
                        <Text fw={700} color="#004236">
                          Aire acondicionado
                        </Text>
                        <Text>
                          {description.car.airCondition === "true"
                            ? "Sí"
                            : "No"}
                        </Text>
                      </Grid.Col>
                      <Grid.Col xs={4}>
                        <Text fw={700} color="#004236">
                          Número de puertas
                        </Text>
                        <Text>{description.car.doorCount}</Text>
                      </Grid.Col>
                      <Grid.Col xs={4}>
                        <Text fw={700} color="#004236">
                          Transmisión
                        </Text>
                        <Text>
                          {description.car.transmissionType === "Manual"
                            ? "Manual"
                            : "Automático"}
                        </Text>
                      </Grid.Col>
                      <Grid.Col xs={4}>
                        <Text fw={700} color="#004236">
                          Cantidad de equipaje
                        </Text>
                        <Text>{description.car.baggageQuantity}</Text>
                      </Grid.Col>
                      <Grid.Col xs={4}>
                        <Text fw={700} color="#004236">
                          Cantidad de pasajeros
                        </Text>
                        <Text>{description.car.passengerQuantity}</Text>
                      </Grid.Col>
                    </Grid>
                  </GlobalCard>
                );
              })}
            </Accordion.Panel>
          </Accordion.Item>
          <Text ta="right">{utils.formatter.format(data?.ALQVEH?.total)}</Text>
        </Accordion>
      )}
      {!!data?.ASISMED?.dataService?.length && (
        <Accordion defaultValue="attendances">
          <Accordion.Item value="attendances">
            <Accordion.Control fz="sm" p={0}>
              Asistencia Médica Internacional
            </Accordion.Control>

            <Accordion.Panel>
              {data?.ASISMED?.dataService?.map((item, index) => (
                <GlobalCard key={index}>
                  <Grid fz="sm">
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Dias de cobertura
                      </Text>
                      <Text>
                        {utils.numDays(
                          JSON.parse(item.descripcionServicio).initialDate,
                          JSON.parse(item.descripcionServicio).finalDate
                        )}
                      </Text>
                    </Grid.Col>
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Detalles
                      </Text>
                      <Text>{JSON.parse(item.descripcionServicio).name}</Text>
                    </Grid.Col>
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Nombre
                      </Text>
                      <Text>{item.nombreServicio}</Text>
                    </Grid.Col>
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Fecha inicio
                      </Text>
                      <Text>
                        {utils.parseDateLocaleFormat(
                          JSON.parse(item.descripcionServicio).initialDate
                        )}
                      </Text>
                    </Grid.Col>
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Fecha fin
                      </Text>
                      <Text>
                        {utils.parseDateLocaleFormat(
                          JSON.parse(item.descripcionServicio).finalDate
                        )}
                      </Text>
                    </Grid.Col>
                  </Grid>
                </GlobalCard>
              ))}
            </Accordion.Panel>
          </Accordion.Item>
          <Text ta="right">{utils.formatter.format(data?.ASISMED?.total)}</Text>
        </Accordion>
      )}
      {!!specialLandTransport?.length && (
        <Accordion defaultValue="specialLandTransport">
          <Accordion.Item value="specialLandTransport">
            <Accordion.Control fz="sm" p={0}>
              Transporte especial
            </Accordion.Control>
            <Accordion.Panel>
              {specialLandTransport?.map((item, index) => (
                <GlobalCard key={index}>
                  <Grid fz="sm">
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Tipo Servicio:
                      </Text>
                      <Text>{item?.tipoServicio}</Text>
                    </Grid.Col>
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Ciudad:
                      </Text>
                      <Text>{`${item?.ciudadOrigen} - ${item?.ciudadDestino}`}</Text>
                    </Grid.Col>
                    {item?.tipoServicio === "DISPONIBLE" && (
                      <Grid.Col xs={4}>
                        <Text fw={700} color="#004236">
                          Lista Destinos:
                        </Text>
                        <Text>{item?.listaDestinos}</Text>
                      </Grid.Col>
                    )}
                    {item?.tipoServicio === "DISPONIBLE" && (
                      <Grid.Col xs={4}>
                        <Text fw={700} color="#004236">
                          Fecha Inicial:
                        </Text>
                        <Text>{`${utils.parseDateLocaleFormat(
                          item?.fechaInicio
                        )} - ${item?.hora}`}</Text>
                      </Grid.Col>
                    )}
                    {item?.tipoServicio === "DISPONIBLE" && (
                      <Grid.Col xs={4}>
                        <Text fw={700} color="#004236">
                          Fecha Final:
                        </Text>
                        <Text>{`${utils.parseDateLocaleFormat(
                          item?.fechaFin
                        )} - ${item?.horaFin}`}</Text>
                      </Grid.Col>
                    )}
                    {item?.tipoServicio === "TRAYECTO" && (
                      <Grid.Col xs={4}>
                        <Text fw={700} color="#004236">
                          Fecha Servicio:
                        </Text>
                        <Text>
                          {utils.parseDateLocaleFormat(item?.fechaInicio)}
                        </Text>
                      </Grid.Col>
                    )}
                    {item?.tipoServicio === "TRAYECTO" && (
                      <Grid.Col xs={4}>
                        <Text fw={700} color="#004236">
                          Hora:
                        </Text>
                        <Text>{item?.hora}</Text>
                      </Grid.Col>
                    )}
                    {item?.tipoServicio === "TRAYECTO" && (
                      <Grid.Col xs={4}>
                        <Text fw={700} color="#004236">
                          Trayecto:
                        </Text>
                        <Text>{`${item?.origen} - ${item?.destino}`}</Text>
                      </Grid.Col>
                    )}
                    <Grid.Col xs={4}>
                      <Text fw={700} color="#004236">
                        Observaciones
                      </Text>
                      <Text>{item?.observacion}</Text>
                    </Grid.Col>
                  </Grid>
                </GlobalCard>
              ))}
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      )}
    </Grid.Col>
  );
};

export default AccordionService;
