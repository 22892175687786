import { Grid, Text, Flex, Divider } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import InfoIcon from "./infoIcon.component";
import { useState } from "react";

const AidValueCard = ({
  utils,
  liquidator,
  dataAid,
  ModalComponent,
  filterByBeneficiaryType,
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [modalContent, setModalContent] = useState({
    normal: 0,
    return: 0,
    title: "",
    isCurrency: false,
  });

  const aid = liquidator?.ViajeTrayecto?.map(
    (item) => item?.auxilioMedico
  ).filter(Boolean);

  return (
    <>
      <ModalComponent
        title={modalContent.title}
        opened={opened}
        onClose={close}
      >
        <Flex justify="space-between" align="center" py={4} px={6}>
          <Text>{modalContent.title} por noche</Text>
          <Text>
            {modalContent.isCurrency
              ? utils.formatter.format(modalContent.normal)
              : modalContent.normal + " días"}
          </Text>
        </Flex>
        <Divider />
        <Flex justify="space-between" align="center" py={4} px={6}>
          <Text>Tarifa retorno</Text>
          <Text>
            {modalContent.isCurrency
              ? utils.formatter.format(modalContent.return)
              : modalContent.return + " días"}
          </Text>
        </Flex>
      </ModalComponent>
      <Grid p={8}>
        <Grid.Col>
          <Grid columns={5} fz="sm" fw={700} color="#004236">
            <Grid.Col span={1}>
              <Text color="#004236">Nombre</Text>
            </Grid.Col>

            <Grid.Col span={1}>
              <Text color="#004236">Tipo</Text>
            </Grid.Col>

            <Grid.Col span={1}>
              <Text color="#004236">Tarifa</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Text color="#004236">Días</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Text color="#004236">Valor</Text>
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col>
          <Grid columns={5} fz="sm">
            <Grid.Col span={1}>
              <Text>
                {`${filterByBeneficiaryType(
                  dataAid,
                  "BENEFICIARIO",
                  "nombres"
                )} ${filterByBeneficiaryType(
                  dataAid,
                  "BENEFICIARIO",
                  "apellidos"
                )}`}
              </Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Text>Beneficiario</Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <OpenModalComponent
                label="Tarifa"
                onClick={() => {
                  open();
                  setModalContent({
                    normal: aid?.[0]?.PerBeneficiario?.tarifaPagarPernocte,
                    return: aid?.[0]?.PerBeneficiario?.tarifaPagarRetorno,
                    title: "Tarifas aplicadas al beneficiario",
                    isCurrency: true,
                  });
                }}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <OpenModalComponent
                label={
                 (aid?.[0]?.PerBeneficiario?.numeroDiasPernocte ?? 0) +
                 (aid?.[0]?.PerBeneficiario?.numeroDiasRetorno ?? 0)
                }
                onClick={() => {
                  open();
                  setModalContent({
                    normal: aid?.[0]?.PerBeneficiario?.numeroDiasPernocte || 0,
                    return: aid?.[0]?.PerBeneficiario?.numeroDiasRetorno || 0,
                    title: "Número de días aplicados al beneficiario",
                    isCurrency: false,
                  });
                }}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <OpenModalComponent
                label={utils.formatter.format(
                  aid?.[0]?.PerBeneficiario?.totalTarifaPersona
                )}
                onClick={() => {
                  open();
                  setModalContent({
                    normal: aid?.[0]?.PerBeneficiario?.totalTarifaPernocte,
                    return: aid?.[0]?.PerBeneficiario?.totalTarifaRetorno,
                    title: "Total valor (beneficiario)",
                    isCurrency: true,
                  });
                }}
              />
            </Grid.Col>
          </Grid>
          {aid?.[0]?.Acompanante && (
            <Grid columns={5} fz="sm">
              <Grid.Col span={1}>
                <Text>
                  {`${filterByBeneficiaryType(
                    dataAid,
                    "ACOMPANANTE",
                    "nombres"
                  )} ${filterByBeneficiaryType(
                    dataAid,
                    "ACOMPANANTE",
                    "apellidos"
                  )}`}
                </Text>
              </Grid.Col>
              <Grid.Col span={1}>
                <Text>Acompañante</Text>
              </Grid.Col>
              <Grid.Col span={1}>
                <OpenModalComponent
                  label="Tarifa"
                  onClick={() => {
                    open();
                    setModalContent({
                      normal: aid?.[0]?.PerAcompanante?.tarifaPagarPernocte,
                      return: aid?.[0]?.PerAcompanante?.tarifaPagarRetorno,
                      title: "Tarifas aplicadas al acompañante",
                      isCurrency: true,
                    });
                  }}
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <OpenModalComponent
                  label={
                    (aid?.[0]?.PerAcompanante?.numeroDiasPernocte ?? 0) +
                    (aid?.[0]?.PerAcompanante?.numeroDiasRetorno ?? 0)
                  }
                  onClick={() => {
                    open();
                    setModalContent({
                      normal: aid?.[0]?.PerAcompanante?.numeroDiasPernocte || 0,
                      return: aid?.[0]?.PerAcompanante?.numeroDiasRetorno || 0,
                      title: "Número de días aplicados al acompañante",
                      isCurrency: false,
                    });
                  }}
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <OpenModalComponent
                  label={utils.formatter.format(
                    aid?.[0]?.PerAcompanante?.totalTarifaPersona
                  )}
                  onClick={() => {
                    open();
                    setModalContent({
                      normal: aid?.[0]?.PerAcompanante?.totalTarifaPernocte,
                      return: aid?.[0]?.PerAcompanante?.totalTarifaRetorno,
                      title: "Total valor (acompañante)",
                      isCurrency: true,
                    });
                  }}
                />
              </Grid.Col>
            </Grid>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
};

const OpenModalComponent = ({ label, onClick }) => {
  return (
    <Flex align="center" gap={4}>
      <Text>{label}</Text>
      <InfoIcon onClick={onClick} small />
    </Flex>
  );
};

export default AidValueCard;
